import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import action_config from './config/config';
import { isEmpty } from "../actions/common";
import { userRegister } from "../actions/axioss/user.axios";
import { event } from "jquery";

export default function EditProfile(props) {


    const navigate = useNavigate();
    const { token, payload, isAdmin } = useSelector(state => state.LoginReducer.User)
    const { Admin_address} = useSelector(state => state.LoginReducer)
    // console.log("valData", payload.WalletAddress);

    const initialValue = {
        DisplayName: payload?.DisplayName ?? "",
        EmailId: payload?.EmailId ?? "",
        Password: '',
        ConfirmPassword: '',
        Youtube: payload?.Youtube ?? "",
        Facebook: payload?.Facebook ?? "",
        Twitter: payload?.Twitter ?? "",
        Instagram: payload?.Instagram ?? "",
        Profile: payload?.Profile ?? "",
        WalletAddress: props?.valData?.WalletAddress ? props.valData?.WalletAddress : payload?.WalletAddress ?? "",
        // Profile:payload?.Profile ? action_config.BACK_URL + '/user/' + payload?.ProfileUrl + '/profile/' + payload?.Profile : '',
        Cover: payload?.Cover ?? "",
        Bio: payload?.Bio ?? "",
        CustomUrl: !isEmpty(payload?.CustomUrl) ? payload?.CustomUrl:props?.valData?.WalletAddress ? props.valData?.WalletAddress : payload?.WalletAddress ?? "",
        Type: 'profile' ,
        spotify : payload?.spotify ?? "",
        youtubeMusic : payload?.youtubeMusic ?? "",
    }
    const[btn , setbtn]= useState(false)
    // console.log('asgdgdsg',payload)
    const dispatch = useDispatch()
    const [formValue, setFormValue] = useState(initialValue)
    const [ValidateError, SetValidateError] = useState('')
    const [onchangeimg, setOnchangeimg] = useState('')
    const [imageVal, setImageVal] = useState('')
    const [oldcostomurl, setOldcustomurl] = useState('')
    const [disablebtn, setDisablebtn] = useState(0)
    // const [locationdata,setlocationdata]=useState()

    const {
        DisplayName,
        WalletAddress,
        EmailId,
        Password,
        ConfirmPassword,
        Youtube,
        Facebook,
        Twitter,
        Instagram,
        Profile,
        Cover,
        Bio,
        CustomUrl,
        Type,
        spotify , 
        youtubeMusic
    } = formValue

    useEffect(() => {
        window.scrollTo(0, 0);
        getProfile()

    }, []);
    // useEffect(()=>{
    //     if(location.state)
    //     setlocationdata(location.state)
    // },[location.state])
    const handleAccountChange=()=>{
        navigate('/')
    }
    const getProfile = async () => {
        if (payload?.Profile) {
            // console.log('shdsh',payload)
            var profileimage = action_config.IMG_URL + '/user/' + payload.CustomUrl + '/profile/' + payload.Profile;
            setOnchangeimg(profileimage);
        }

    }
    const onChange = (e) => {
        setDisablebtn(0)
        const { value, id } = e.target
        if (id == 'CustomUrl'){
        setOldcustomurl(formValue.CustomUrl);
            var formData = { ...formValue, ...{ ['CustomUrl']: value.toString().replace(/\s/g, '').toLowerCase(),"CUS": true } }
        }else
            var formData = { ...formValue, ...{ [id]: value } }
        setFormValue(formData)
    }
    const Validation = useCallback((data) => {
        const spotifyPattern = /^https:\/\/open\.spotify\.com\/(track|album|playlist|artist)\/[A-Za-z0-9]+/;
        const youtubeMusicPattern = /^https:\/\/music\.youtube\.com\/watch\?v=[A-Za-z0-9_-]+/;
        const twitterPattern =/^https:\/\/x\.com\/[A-Za-z0-9_]{1,15}\/(?:[0-9]+|events\/[0-9]+)/;
        const instagramPattern = /^https:\/\/(www\.)?instagram\.com\/p\/[A-Za-z0-9_-]+/;
        const facebookPattern = /^https:\/\/www\.facebook\.com\/[A-Za-z0-9.]+\/posts\/[0-9]+/;
        const youtubePattern = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:@[\w\-]+|watch\?v=[\w\-]+)$/;
;

    
        //console("validation Checking", data)
        let ValidateError = {}
        let imageSize = 5000000;

    console.log('vavvahivgasgd' , data )
        if (!data.DisplayName) ValidateError.DisplayName = 'DisplayName Required'
        // if (!data.CustomUrl) ValidateError.CustomUrl = 'CustomUrl Required'
        // if (data.CustomUrl && !(action_config.notSpecil).test(data.CustomUrl)) ValidateError.CustomUrl = 'CustomUrl Must Be Allowed Only Letters and Numbers'
      if(data.spotify){
        if (!spotifyPattern.test(data.spotify)) {

ValidateError.spotify = 'spotify url is invalid '
        }
    }

//     if(data.youtubeMusic){
//         if (!youtubeMusicPattern.test(data.youtubeMusic)) {

//         ValidateError.youtubeMusic = 'youtubeMusic url is invalid '
//         }
//     }
    if (data.Instagram) {
        if (!instagramPattern.test(data.Instagram)) {
            ValidateError.Instagram = 'Instagram URL is invalid';
        }
    }
    
    // Facebook validation
    if (data.Facebook) {
        if (!facebookPattern.test(data.Facebook)) {
            ValidateError.Facebook = 'Facebook URL is invalid';
        }
    }
    
    // YouTube validation
    if (data.Youtube) {
        if (!youtubePattern.test(data.Youtube)) {
            ValidateError.Youtube = 'YouTube URL is invalid';
        }
    }

    if (data.Twitter) {
        if (!twitterPattern.test(data.Twitter)) {
            ValidateError.Twitter = 'twitter URL is invalid';
        }
    }

        if (!data.EmailId) ValidateError.EmailId = 'EmailId Required'
        if (data.EmailId && !(action_config.EMAIL).test(data.EmailId)) ValidateError.EmailId = 'Invalid Email Format'
        SetValidateError(ValidateError)
        console.log('ValidateErrorValidateError' , ValidateError)
        return ValidateError
    }, [ValidateError])

    const FormSubmit = async () => {
        var formData;
        setbtn(true)
        const id = toast.loading("Updating Progress..")
        let error = Validation(formValue)

        if(formValue?.CustomUrl==""){
            formData = { ...formValue, ...{ ['CustomUrl']: payload?.WalletAddress } }
            // setFormValue(formData)
        
        }
        else
        {
            formData=formValue
        }
        if (!isEmpty(error)) {
            setDisablebtn(1)
            SetValidateError(error)
            toast.update(id, { render: 'Validation Failed', type: 'error', isLoading: false, autoClose: 1000, position: 'top-center', closeButton: true, closeOnClick: true })

        }
        else {

            let Resp = await userRegister(formData)
            console.log("Checkinxxxg the Validation", Resp);
            if (Resp.success === 'success') {
                let form=formData;
                form.CUS=false
                setFormValue(form)
                toast.update(id, { render: 'Saved successfully', type: 'suceess', isLoading: false, autoClose: 1000, position: 'top-center', closeButton: true, closeOnClick: true })
                setDisablebtn(0)

                // GetProfile();
              

                dispatch({
                    type: 'Register_Section',
                    Register_Section: {
                        User: {
                            payload: Resp.data,
                            isAdmin: Resp.data?.isAdmin ? Resp.data?.isAdmin == 'Yes' ? true : false : isAdmin,
                            token: Resp.token ? Resp.token : token
                        }
                    }
                })

                dispatch({
                    type: 'Admin_address',
                    Admin_address: {
                        Admin_address : !Admin_address
                    }
                })

                if (Resp.data.CustomUrl) document.cookie = 'user' + "=" + Resp.data.CustomUrl + ";" + ";path=/";

                // setTimeout(() => {
                    navigate(`/profile/${Resp.data.CustomUrl}`)
                // }, 1000);


            }
            else {
                console.log("ddddddddddd");
                let form=formData;
                form.CUS=false
                setFormValue(form)
                setDisablebtn(1)
                toast.update(id, { render: Resp?.msg ? Resp?.msg : 'Validation Failed', type: 'error', isLoading: false, autoClose: 1000, position: 'top-center', closeButton: true, closeOnClick: true })
                SetValidateError({ ...ValidateError, ...Resp.validate })
            }
        }

        setbtn(false)
    }
    // console.log("afawsfr", formData);
    return (
        <>
            <div className="inner-content profile">
                {/* <h3 className="profilename">{DisplayName}</h3> */}
                <h4 className="title-dashboard crossfitFont vert__subpageTitle" data-aos="fade-right">Edit Profile</h4>
                <form className="form-edit-profile">
                    <div className="user-profile mt-5">
                        <div className="title firaFont vert__subSecTitle">User details</div>
                        {/* <fieldset className="mb-4">
                                                <h6>Profile</h6>
                                                <input type="file" id="Profile" onChange={(e) => handleFile(e)} required />
                                                {ValidateError && ValidateError.Profile && <span className="error_msg">{ValidateError.Profile}</span>}
                                            </fieldset> */}
                        {/* <fieldset className="mb-4">
                                                <h6>Cover Image</h6>
                                                <input type="file" id="Cover" onChange={(e) => CoverImg(e)} required />
                                            </fieldset> */}
                        {/* {onchangeimg === '' &&
                                        <img src={require("../assets/images/edit-img.png")} alt="The Redemption" className="img" />
                                    } */}
                                        <fieldset className="mb-4">
                            <h6 className="firaFont">User Address</h6>
                            <input disabled type="text" id="creatoraddress" value={WalletAddress} placeholder="creatoraddress" className="firaFont"  required autoComplete="off" />
                          
                        </fieldset>

                        <fieldset className="mb-4">
                            <h6 className="firaFont">Full Name<span className="required">*</span></h6>
                            <input type="text" id="DisplayName" value={DisplayName} placeholder="Hernes" className="firaFont" onChange={onChange} required autoComplete="off" />
                            {ValidateError && ValidateError.DisplayName && <span className="error_msg">{ValidateError.DisplayName}</span>}
                        </fieldset>
                        <fieldset className="mb-4">

                            <h6 className="firaFont">Custom Url(optional)<span className="required"></span></h6>
                            <input type="text" id="CustomUrl" value={CustomUrl} placeholder="hernes" className="firaFont" onChange={onChange} required autoComplete="off" />

                            {ValidateError && ValidateError.CustomUrl && <span className="error_msg">{ValidateError.CustomUrl}</span>}
                        </fieldset>
                    </div>
                    <div className="user-profile">
                        <div className="title firaFont vert__subSecTitle">Contact details</div>
                        <fieldset className="mb-4">
                            <h6 className="firaFont">Email Address<span className="required">*</span></h6>
                            <input id="EmailId" type="text" value={EmailId} placeholder="hernes@gmail.com" className="firaFont" onChange={onChange} required autoComplete="off" />
                            {ValidateError && ValidateError.EmailId && <span className="error_msg">{ValidateError.EmailId}</span>}
                        </fieldset>
                        <fieldset className="mb-4">
                            <h6 className="firaFont">Bio</h6>
                            <input id="Bio" type="text" value={Bio} placeholder="Write Something about yourself" className="firaFont" onChange={onChange} maxLength={150} autoComplete="off" />
                        </fieldset>
                        {/* <fieldset className="mb-4">
                            <h6 className="firaFont">Youtube Music Link</h6>
                            <input id="youtubeMusic" value={youtubeMusic} type="text" placeholder="Youtube Music Address" className="firaFont" onChange={onChange} autoComplete="off" />
                            {ValidateError && ValidateError?.youtubeMusic && <span className="error_msg">{ValidateError?.youtubeMusic}</span>}
                       
                        </fieldset> */}
                        <fieldset className="mb-4">
                            <h6 className="firaFont">Spotify Link</h6>
                            <input id="spotify" value={spotify} type="text" placeholder="Spotify Address" className="firaFont" onChange={onChange} autoComplete="off" />
                            {ValidateError && ValidateError?.spotify && <span className="error_msg">{ValidateError?.spotify}</span>}
                      
                        </fieldset>
                        <fieldset className="mb-4">
                            <h6 className="firaFont">Youtube Link</h6>
                            <input id="Youtube" value={Youtube} type="text" placeholder="Youtube Address" className="firaFont" onChange={onChange} autoComplete="off" />
                            {ValidateError && ValidateError?.Youtube && <span className="error_msg">{ValidateError?.Youtube}</span>}
                        
                        </fieldset>
                        <fieldset className="mb-4">
                            <h6 className="firaFont">X (twitter) Link</h6>
                            <input id="Twitter" value={Twitter} type="text" placeholder="Twitter Address" className="firaFont" onChange={onChange} autoComplete="off" />
                            {ValidateError && ValidateError?.Twitter && <span className="error_msg">{ValidateError?.Twitter}</span>}
                      
                        </fieldset>
                        <fieldset className="mb-4">
                            <h6 className="firaFont">Facebook Link</h6>
                            <input id="Facebook" value={Facebook} type="text" placeholder="Facebook Address" className="firaFont" onChange={onChange} autoComplete="off" />
                            {ValidateError && ValidateError?.Facebook && <span className="error_msg">{ValidateError?.Facebook}</span>}
                   
                        </fieldset>
                        <fieldset className="mb-4">
                            <h6 className="firaFont">Instagram Link</h6>
                            <input id="Instagram" value={Instagram} type="text" placeholder="Instagram Address" className="firaFont" onChange={onChange} autoComplete="off" />
                            {ValidateError && ValidateError?.Instagram && <span className="error_msg">{ValidateError?.Instagram}</span>}
                   
                        </fieldset>
                    </div>
                    <div className="text-center">
                        <button className="btn-form vert__cmnOutBtn" 
                        disabled={btn} onClick={FormSubmit} type="button">
                            Update Settings
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}