 import React,{useState} from 'react';
import Modal from 'react-bootstrap/Modal';
// import Image from "../assets/images/banner.png";
import Select from "react-select";
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
// import "../assets/css/modal.css";
import { report } from '../../actions/axioss/user.axios';

export function Report({item,closePop,onhide , OpenPopup}){
  console.log('hiiiii',item)
  const [Message,SetMessage] = useState('')
   const [Btn,SetBtn] = useState('start')
   const { payload } = useSelector(state => state.LoginReducer.User)
   const [ValidateError, SetValidateError] = useState({});
   
   const FormSubmit =   async()  =>  {
      SetBtn('process')
      const id = toast.loading('Reporting')
      if(Message == ''){
        toast.update(id, { render: "Enter Report Message", type: "success", isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true })
        SetValidateError({message:"Enter Report Message"})
      }
      else{
        var senddata = {
           ...item ,
          Address  :  payload.WalletAddress,
          CustomUrl  :  payload.CustomUrl,
          Message  :  Message
        }
        let Resp = await report(senddata)
        if(Resp){
          toast.update(id, { render: Resp?.msg, type: Resp?.success, isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true })
          SetBtn('done')
          closePop()
          }
      }

   }
return(
<Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={OpenPopup}
    >
      <Modal.Header>
      {/* <button type="button" class="btn-close" aria-label="Close" onClick={closePop}></button> */}
        <Modal.Title id="contained-modal-title-vcenter" className='share_modal_ttl'>
      Report this Profile ?
      {/* <button className="modal_close_btn" aria-label="Close" onClick={closePop}>&times;</button> */}
      <button type="button" className='vert__cmnModalCloserr vert__buyNowCloser' aria-label="Close" onClick={()=> {closePop(); onhide();}}><i class="fa-solid fa-xmark" /></button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
     <div className='approvetransaction mb-5'>
        <p className='mt-4 clrwhite'>It is a long established fact that a reader will be distracted by the readable content</p>
        <p className="pt-5 clrwhite pb-3 text-left">Message</p>
        <textarea rows="4" cols="42" placeholder="Tell us some details" onChange={(e)=>SetMessage(e.target.value)}/>
        {ValidateError.message && (
          <span className="text-danger img-file">
          {ValidateError.message}
          </span>
        )}
        </div>
        <div className='connecwalletbtns d-flex justify-content-center mt-3'>
            {/* <button className="connectwallet primary" onClick={handleClose3}>Cancel Bid</button> */}
            <button className="vert__cmnOutBtn" onClick={FormSubmit}>Submit</button>
        </div>
      </Modal.Body> 
    </Modal>
)
}